import {
  SELECT_AIRCRAFT, SELECT_YEAR, SELECT_USER, SELECT_LESSON,
  SELECT_ACCOUNT, TOGGLE_ADMIN_MODE, SELECT_ACTIVITY,
} from '../types'


export const selectAircraftId = aircraftId => dispatch => new Promise(resolve => {
  dispatch({
    type: SELECT_AIRCRAFT,
    payload: aircraftId,
  })
  resolve()
})

export const selectYear = year => dispatch =>
  dispatch({
    type: SELECT_YEAR,
    payload: year,
  })

export const selectUserId = userId => dispatch =>
  dispatch({
    type: SELECT_USER,
    payload: userId,
  })

export const selectAccountId = accountId => dispatch =>
  dispatch({
    type: SELECT_ACCOUNT,
    payload: accountId,
  })

export const selectLessonId = lessonId => dispatch =>
  dispatch({
    type: SELECT_LESSON,
    payload: lessonId,
  })

export const setAdminMode = val => dispatch =>
  dispatch({
    type: TOGGLE_ADMIN_MODE,
    payload: val,
  })

export const selectActivityId = activityId => dispatch =>
  dispatch({
    type: SELECT_ACTIVITY,
    payload: activityId,
  })
